import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'

import * as s from './shows.module.css'

const Shows = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    content,
  } = data.wordpressPage
  return (
    <Layout backgroundColor="#f6f4f2">
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <div className={s.container}>
        {content && (
          <div
            className={s.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </Layout>
  )
}

export default Shows

Shows.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      content: PropTypes.any,
    }),
  }),
}

export const pageQuery = graphql`
  query Shows {
    wordpressPage(uri: { eq: "/shows/" }) {
      seo {
        title
        description
      }
      content
    }
  }
`
